export default {
    translation: {
        'internet_connection_error': "Une erreur est survenue, veuillez réessayer plus tard.",
        'shared.french': 'English',
        'shared.english': 'English',
        'login_page.title': 'Comii | Connexion',
        'login_page.form_title': 'Connexion',
        'login_page.form_action_1': 'Continuer',
        'login_page.form_country': 'Pays',
        'reset_page_title': 'Réinitialisation du mot de passe',
        'reset_page_action': 'Send code',
        'reset_page_subtitle': 'Veuillez saisir l\'adresse e-mail liée à votre compte pour réinitialiser votre mot de passe.',
        'reset_page_back': 'Revenir au login',
        'login_page_title': 'Connectez-vous à votre compte',
        'new_password_title': 'Crée votre nouveau mot de passe',
        'password_reset_success': 'Mot de passe réinitialiser avec succès',
        'new_password': 'Nouveau mot de passe',
        'new_password_conf': 'Confirmation du mot de passe',
        'label_password_is_not_same': 'Les deux mots de passes doivent être identiques',
        'validate_reset_password_title': 'Nous vous avons envoyé un code à ',
        'validate_reset_password_action_0': 'Cet email n\'est pas la votre ?',
        'validate_reset_password_action_1': 'Changer l\'email',
        'new_password_action': 'Créer le mot de passe',
        'reset_password_footer': 'Si vous ne trouvez pas l\'e-mail, vérifiez votre boîte de courrier indésirable.',
        'global_field_require': 'Ce champ est requis',
        'global_field_email': 'Veuillez saisir une adresse email correct',
        'login_page.form_phone': 'Numéro de téléphone',
        'login_page.password_forget': 'Mot de passe oublié',
        'login_page.form_password': 'Mot de passe',
        'login_page.form_email': 'Adresse mail / ID',
        'category_add_title': 'Ajouter une catégorie',
        'navigation_config_1': 'Journal Comii',
        'navigation_config_2': 'Statistiques',
        'navigation_config_3': 'Requêtes',
        'navigation_config_4': 'Services',
        'navigation_config_5': 'Utilisateurs',
        'navigation_config_6': 'Finances',
        'navigation_config_7': 'Déconnexion',
        'profile_user': 'Profil',
        'global_search': 'Rechercher un utilisateur',
        'password_user': 'Mot de passe',
        "home_user_stats_year": "Cette année",
        "home_user_total_senior": "Total senior",
        "home_user_total_helfer": "Total Helfer",
        "home_user_total_request": "Requête envoyé",
        "home_user_total_apart": "Demande traitées",
        "home_user_trend_senior": "+{{trend}} new registrants",
        "home_user_trend_request": "+{{trend}} new request",
        "home_user_trend_appart": "+{{trend}} new",
        "home_user_trend": "Trend",
        "home_user_customer_list": "Liste d'utilisateurs",
        "home_user_customer_filter_all": "Tout les utilisateurs",
        "home_user_customer_filter_seniors": "Seniors",
        "home_user_customer_filter_helfer": "Helfers",
        "home_user_customer_name": "Nom du client",
        "home_user_customer_created_at": "Inscrit le",
        "home_user_customer_phone": "Téléphone",
        "home_user_customer_type": "Type de compte",
        "home_user_customer_status": "Status",
        "home_user_customer_actions": "Actions",
        "home_user_customer_online": "En ligne",
        "home_user_aufgabe_status_all": "Tout les status",
        "home_user_aufgabe": "Aufgabe",
        "home_user_aufgabe_id": "ID",
        "home_user_aufgabe_name": "Nom du service",
        "home_user_aufgabe_von": "Créé à partir de",
        "home_user_aufgabe_date": "Date",
        "home_user_aufgabe_status": "status",
        "home_user_stats_month": "Ce mois",
        "home_user_stats_day": "Cette journée",
        "global_rows_per_page": "Par page",
        "filter_user_stats_per_month": "Par mois",
        "filter_user_stats_per_year": "Par ans",
        "home_user_stats_online": "{{count}} on line",
        "home_user_stats_progress": "{{count}} in progress",
        "home_user_task_status_all": "Tous les statuts",
        "order_analytics": "Analyse des commandes",
        "home_user_aufgabe_status_gesendet": "Tâche envoyée",
        "home_user_aufgabe_status_gestartet": "Tâche démarrée",
        "home_user_aufgabe_status_beendet": "Tâche terminée",
        "home_user_aufgabe_status_akzeptiert": "Tâche acceptée",
        "home_user_aufgabe_status_bezahlt": "Tâche payée",

        'page_statistic_graph_zone_title': "Statistiques",
        'page_statistic_graph_1_title': "Statistiques sur les utilisateurs enregistrés par mois",
        'page_statistic_label_care_per_month': "Nombre de soins par mois",
        'page_statistic_label_request_per_month': "Statistiques sur le nombre de demandes par mois",
        'page_statistic_label_this_week': "cette semaine",
        'page_statistic_label_this_month': "ce mois",
        'page_statistic_label_last_month': "le mois passé",
        'page_statistic_label_this_trimester': "ce trimestre",
        'page_statistic_ratio_helfer_senior': "Rapport Helfer/Senior",
        'page_statistic_ratio_senior_helfer': "Rapport Senior/Helfer",
        'page_statistic_average_duration_assistance': "Durée moyenne de l'aide",
        'page_statistic_spontaneity_average': "Spontanéité Moyenne",
        'page_statistic_average_coast_per_intervention': "Coût moyen par intervention",
        'page_statistic_average_service_duration': "Durée moyenne d'un service ",
        'page_statistic_average_duration_of_execution_service': "Durée moyenne d'exécution d'un service",
        'page_statistic_average_between_start_end_service': "Temps moyen entre la demande du service et le début de son exécution",
        'page_statistic_average_between_start_accept_service': "Temps moyen entre la demande d'un service et son acceptation par un secouriste",
        'page_statistic_average_between_create_accept_service': "Délai moyen entre l'acceptation d'un service et le début de l'exécution du service",
        'page_statistic_average_coat_per_service': "Coût moyen par service",

        'user_page.title': 'Liste des utilisateurs',
        'user_page.username': 'Noms',
        'user_page.created_at': 'Inscrit le',
        'user_page.email': 'E-mail',
        'user_page.block_user': "Bloquer",
        'user_page.unblock_user': "Débloquer",
        'user_page.show_user_details': "Details",
        'user_page.update_user': "Modifier",
        'user_page.delete_user': "Supprimer l'utilisateur",
        'user_page.delete_user_confirm_message': "Vous êtes sur le point de supprimer cet utilisateur, Il ne sera plus visible dans le système",
        'user_page.delete_user_confirm': "Voulez-vous vraiment supprimer cet utilisateur?",
        'user_page.delete_success': 'Utilisateur supprimé avec succès',
        'user_page.block_user_confirm_message': "Vous êtes sur le point de bloquer cet utilisateur, il ne pourra plus acceder au système",
        'user_page.block_user_confirm': "Voulez-vous vraiment bloquer cet utilisateur ?",
        'user_page.unblock_user_confirm_message': "Vous êtes sur le point de débloquer cet utilisateur, il pourra a nouveau acceder au système",
        'user_page.unblock_user_confirm': "Voulez-vous vraiment débloquer cet utilisateur ?",

        'user_detail_page.title': 'Detail utilisateur',
        'user_detail_page.send_message': 'Envoyer un message',
        'user_detail_page.total_rating': 'Note totale',
        'user_detail_page.comments': 'Commentaires',
        'user_detail_page.tab_info': 'Informations',
        'user_detail_page.tab_services': 'Services',
        'user_detail_page.tab_stats': 'Statistiques',
        'user_details_page.tab_alleDaten':'Toutes les données',
        'user_detail_page.firstname': 'Nom',
        'user_detail_page.lastname': 'Prénom',
        'user_detail_page.civility': 'Civilité',
        'user_detail_page.gender': 'Genre',
        'user_detail_page.birthday': 'Date de naissance',
        'user_detail_page.phone': 'Telephone',
        'user_detail_page.address': 'Adresse',
        'user_detail_page.email': 'Email',
        'user_detail_page.password': 'Mot de passe',
        'user_detail_page.last_login': 'Dernière connexion',
        'user_detail_page.verified': 'Vérifié',
        'user_detail_page.joined': 'Inscrit le',
        'user_detail_page.card_info': 'Informations bancaire',
        'user_detail_page.reset_password': 'Modifier le mot de passe',
        'user_detail_page.delete_session': 'Supprimer la session',

        'user_request.title': 'Demande de service',
        'user_request.status_paid': 'Service payé',
        'user_request.status_outstanding': 'Service accepté',
        'user_request.status_pending': 'Service encours',
        'user_request.status_pending_helfer': 'Service envoyé',
        'user_request.status_completed': 'Service réalisé',
        'user_request.status_cancelled': 'Service annulé',
        'user_request.status_paused': 'Service annulé',
        'user_request.status_failed': 'Service échoué',
        'user_request.status_expired': 'Service expiré',
        'user_request.status_rejected_by_all': 'Service rejeté',
        'user_request.start_at': 'Démarre à',
        'user_request.pause_at': 'Pause à',
        'user_request.resume_at': 'Relancé à',
        'user_request.end_at': 'Terminé à',
        'user_request.rating_helfer': 'Note helfer',
        'user_request.service_summary': 'Résumé du service',
        'user_request.file_attachment': 'Fichiers',
        'user_request.description': 'Description',
        'user_request.posted_on': 'Posté le',
        'user_request.category': 'Catégorie',
        'user_request.task_name': 'Nom du service',
        'user_request.service_number': 'Numéro du service',
        'user_request.detail': 'Details du service',
        'user_request.all_status': 'Tous les status',
        'user_request.last_service_request': 'Dernières demandes de service',

        'user_comment.title': 'Commentaires',
        'user_comment.total_comments': 'Un total de :count commentaires',
        'user_comment.load_more': 'Afficher plus',
        'today': "Aujourd’hui",
        'yesterday': 'Hier',

        'request_page.title': 'Demandes de service',
        'request_page.service_id': 'ID',
        'request_page.service_name': 'Service',
        'request_page.category': 'Catégorie',
        'request_page.created_by': 'Créé par',
        'request_page.created_at': 'Créé le',
        'request_page.status': 'Statut',
        'user_request.all_services': 'Tous les services',
        'request_page.start_date': 'De',
        'request_page.end_date': 'A',
        'request_page.file_type': 'Format',

        'global_label.export': "Exporter",
        'global_label.latest_update': "Mise à jour",
        'global_label.delete': "Supprimer",
        'global_label.add': "Ajouter",
        'global_label.no': "Non",
        'global_label.yes': "Oui!",
        'global_label.view_detail': 'View details',
        'global_label.status_paid': 'Payé',
        'global_label.status_outstanding': 'accepté',
        'global_label.status_pending': 'en cours',
        'global_label.status_pending_helfer': 'envoyé',
        'global_label.status_completed': 'réalisé',
        'global_label.status_cancelled': 'annulé',
        'global_label.status_paused': 'en pause',
        'global_label.status_rejected_by_all': 'rejeté',
        'global_label.status_failed': 'échoué',
        'global_label.status_expired': 'expiré',
        'at': 'à',
        "global_label_on_average": "en moyenne",
        "global_today_date": "Aujourd’hui",
        "global_yesterday_date": "Hier",
        "messages_textfield": "Votre message",
        "send_messages_textfield": "Envoyer",
        "home_user_aufgabe_status_PENDING_HELFER": "Tâche envoyée",
        "home_user_aufgabe_status_PENDING": "Tâche démarrée",
        "home_user_aufgabe_status_COMPLETED": "Tâche terminée",
        "home_user_aufgabe_status_OUTSTANDING": "Tâche acceptée",
        "home_user_aufgabe_status_PAID": "Tâche payée",
        "global_label_notifications": "Notifications",
        "label_unread": "Non lues",
        'label_all_notification': "Toutes",
        'btn_label_edit_profile': "modifier le profil",
        'global_label_save': "Enregistrer",
        "label_civility_HERR": "M.",
        "label_civility_FRAU": "Mme.",
        "label_civility_ANDERE": "Other",
        "label_gender_MÄNNLICH": "Masculin",
        "label_gender_WEIBLICH": "Féminin",
        "label_gender_ANDERE": "Autre",
        'label_manage_password': "Gérer le mot de passe",
        'label_manage_password_description': "Si vous changez votre mot de passe, vous devrez vous reconnecter pour vérifier votre identité",
        "btn_label_edit_password": "Modifier le mot de passe",
        "label_old_password": "Mot de passe actuel",
        "update_avatar_success_message": "Photo de profil mis à jour avec succès",
        "update_infos_success_message": "Infomations mis à jour avec succès",
        "btn_label_add_user": "Utilisateur",
        "add_user_modal_title": "Créer un nouvel utilisateur",
        "label_admin": "Administrateur",
        "label_location": "Ville",
        "label_postal_code": "Code postal",
        "label_street": "Rue",
        "label_street_nr": "N°",
        "add_user_success_message": "Utilisateur ajouté avec succès",
        "service_page.services": "Services",
        "service_page.category": "Catégorie",
        "service_page.add_service": "Créer un service",
        "service_page.edit_service": "Modifier le service",
        "service_page.delete_service": "Supprimer le service",
        'service_page.delete_service_confirm_message': "Vous êtes sur le point de supprimer ce service, il ne sera plus visible aux utilisateurs",
        'service_page.delete_service_confirm': "Voulez-vous vraiment supprimer ce service ?",
        'service_page.delete_success': 'Service supprimé avec succès',
        'service_page.select_service_category': '-- Sélectionné la catégorie du service',
        'service_page.update_service': 'Mettre a jour service',
        'service_page.create_new_service': 'Créer le service',
        'service_page.service_title_placeholder': 'Donnez un nom au service ,SVP',
        "label_password_is_not_strong": "Le mot de passe doit contenir au moins 8 caractères, des lettres majuscules, des chiffres et des caractères spéciaux (!@#$%^&*)",
        "service_label_match_maker": "Match maker",
        "label_show_helfer": "Afficher le helfer",
        "label_check_match": "Vérifier le match",
    }
};
